@font-face {
  font-family: Helvetica Neue Light;
  src: local("HelveticaNeueLight"),
    url("./fonts/HelveticaNeueLight.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: Helvetica Neue Medium;
  src: local("HelveticaNeueMedium"),
    url("./fonts/HelveticaNeueMedium.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: Helvetica Neue Bold;
  src: local("HelveticaNeueBold"),
    url("./fonts/HelveticaNeueBold.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: Helvetica Neue Black;
  src: local("HelveticaNeueBlack"),
    url("./fonts/HelveticaNeueBlack.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "SaoTorpes";
  src: local("SaoTorpes"), url("./fonts/SaoTorpes.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Loved By The King";
  src: local("Loved By The King"),
    url("./fonts/LovedbytheKing-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Starborn";
  src: local("Starborn"), url("./fonts/Starborn.ttf") format("truetype");
  font-weight: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;1,300&display=swap");

:root {
  --no-of-slides: 13;
  --slides-in-view: 5;
  --slide-width: 250px;
  --margin: 40px;
  --slide-width-w-margin: 290px;
  --custom-width: 200px;
  --slide-height: 200px;
  --iteration-time: 60s;
  --base-light-color: rgb(232, 232, 232);
  --shadow-color: #999999;
}

* {
  padding: 0;
  box-sizing: border-box;
}

.Toastify__toast-body {
  font-size: 2rem;
  font-weight: 500;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto Mono", sans-serif;
  color: #252525;
  font-weight: 400;
  background-image: url("texturedbg2.png");
  background-size: cover; /* optional */
  background-attachment: fixed;
  border-bottom: 1.6rem solid #202020;
  min-height: 100vh;
  padding: 0px;
  z-index: 100;
}

.hide {
  display: none !important;
}

.loader {
  position: fixed;
  color: #fff;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

#main-container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 3000px !important;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
}

.btn {
  color: inherit;
  font-family: inherit;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: #edc84b;
  padding: 1.4rem 3.2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  background-color: #e9bb24;
}

/* CREATE MEMES */

#meme-note {
  padding-top: 20px;
  display: flex;
  color: #08415c;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
}

.overlay-parts select {
  border: 2px solid #08415c;
  color: #08415c;
  box-shadow: -3px 4px 0 0 #353535;
  border-radius: 8px;
  font-size: 3rem;
  font-family: Calibri;
  background-color: rgba(255, 255, 255, 0.854);
  text-transform: uppercase;
  height: 5rem;
  width: 25rem;
  margin: 10px !important;
}

.meme-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base-image {
  width: 25%;
  object-fit: cover;
  border: 8px solid #fff;
  z-index: 2;
}

.overlay-parts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px !important;
}

.head-overlay,
.hand-overlay,
.background-overlay,
.eyes-overlay {
  position: absolute;
  top: 0;
  left: 37.5%;
  width: 25%;
  height: 100%;
  object-fit: cover;
}

.head-overlay {
  z-index: 6;
}

.hand-overlay,
.eyes-overlay {
  z-index: 5;
}

.background-overlay {
  z-index: 1;
}

#download-meme {
  font-size: 2rem;
  font-family: starborn, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: #ff6b90;
  box-shadow: -5px 6px 0 0 #ff3366;
  border: 2px solid #000;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 30px auto !important;
  width: 200px;
}

/* CREATE MEMES */

/**************************** TOP HEADER ****************************/
#loader-art {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.subPageHeader {
  align-self: stretch;
  margin-bottom: 10rem;
  padding-left: 100px;
  padding-right: 100px;
}

.subPageHeader h1 {
  /* Non-accessible color */
  margin-bottom: 30px !important;
  color: #2e2e2e !important;
  /* color: #af8602; */
  font-family: Helvetica Neue Black;
  text-transform: uppercase;
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  letter-spacing: 3px;
  position: relative;
  width: 100%;
  display: block;
}

.subPageHeader p {
  text-align: left;
  color: #202020;
  font-size: 1.8rem;
  text-align: justify;
  font-family: Helvetica Neue Light;
  letter-spacing: 0.05em;
  line-height: 1.8em;
}

.header {
  align-self: stretch;
  margin-bottom: 30px;
}

.header h3 {
  text-align: center;
  font-family: Helvetica Neue Light;
  font-style: normal !important;
  font-size: 2rem;
}

.header h1 {
  /* Non-accessible color */
  margin-bottom: 30px !important;
  color: #2e2e2e !important;
  /* color: #af8602; */
  font-family: Calibri;
  text-transform: uppercase;
  text-align: center;
  font-size: 8rem;
  font-weight: 800;
  letter-spacing: 3px;
  position: relative;
  width: 100%;
  display: block;
}

.header h1::before {
  left: 0;
}

.header h1::after {
  right: 0;
}

.socialsHeader {
  color: #beefe8;
  text-align: center;
}

.socialsHeader h4 {
  display: inline-block;
  padding: 1rem 2rem;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: 300;
  color: #08415c;
}

.social-icon {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px !important;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #000;
}
.social-icon a::before {
  content: "";
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 50%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

/**************************** TOP HEADER ****************************/

.categories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 650px;
  padding-left: 200px;
  padding-right: 200px;
}

.category-tile {
  width: 95%;
  height: 40rem;
  margin-bottom: 5px !important;
  opacity: 0.9;
  background-size: 120%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}

.category-tile-overlay {
  position: absolute; /* position the overlay absolutely */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* black background with 50% opacity */
  opacity: 0.8;
  transition: opacity 0.3s;
  z-index: -1;
}

.category-text {
  font-family: Helvetica Neue Medium;
  font-size: 1.8rem;
  color: #202020;
  text-align: center;
  z-index: 5;
  padding: 5px;
  transition: font-size 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.3s;
}

#project-list-section {
  padding-left: 100px;
  padding-right: 100px;
}

.categoryWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  width: 300px;
  height: 50px;
  z-index: 3;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: all 0.3s;
}

.categoryWrapperWriting {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-color: transparent;
  width: 300px;
  height: 100px;
  z-index: 3;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 1s;
}

.categoryWrapperWriting .category-text {
  font-family: Helvetica Neue Medium;
  font-size: 1.8rem;
  color: #202020;
  text-align: center;
  z-index: 5;
  padding: 5px;
  transition: font-size 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.3s;
}

.categoryWrapper:hover {
  border: 1px solid #000;
}

.categoryWrapper:hover .category-text {
  color: #000;
}

.category-tile:hover {
  background-size: 100%; /* zoom in the image on hover */
  opacity: 1;
  filter: grayscale(0%);
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  border: 20px solid transparent;
}

.category-tile:hover .categoryWrapper {
  border: 1px solid #fff;
}

.category-tile:hover .category-tile-overlay {
  opacity: 0.3; /* dim the image more on hover */
}

.category-tile:hover .category-text {
  font-size: 1.8rem; /* enlarge the text on hover */
}

/**************************** MAIN BANNER ****************************/

#workmenu-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-item {
  font-size: 2rem;
  font-family: Helvetica Neue Light;
}

.dropdown-menu.show {
  transform: translate3d(-60px, 32px, 0px) !important;
}

.dropdown-button {
  display: flex;
  margin-left: 5px;
  background-color: transparent !important;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  padding-bottom: 5px;
  vertical-align: center;
  justify-content: center;
}

#work-dropdown {
  background-color: transparent;
  color: #202020;
  border: none;
  padding: 0;
  font-size: 2rem;
}

#menu-bar {
  color: #fff;
  width: 100%;
  height: 10rem;
  background-color: rgba(255, 255, 255, 0.15);
  box-sizing: content-box;
  z-index: 100;
}

#menu-bar .row {
  margin-right: 0px !important;
}

#menu-bar h1 {
  color: #292929 !important;
  font-size: 1.3rem;
  font-weight: 800;
  letter-spacing: 0.32rem;
  text-align: center;
  text-transform: uppercase;
  font-family: Helvetica Neue Black;
  transition: text-decoration 0.3s;
}

#menu-bar a {
  text-decoration: none;
}

#menu-bar h1:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.center-menu-text {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.align-items-center {
  height: 100%;
}

.mainbanner {
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 0px !important;
}

#hero-wrap {
  height: fit-content;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainbanner h1 {
  font-size: 5rem;
  color: #2e2e2e !important;
  text-transform: uppercase;
  font-family: Helvetica Neue Black;
  letter-spacing: normal;
  line-height: 100%;
}

.mainbanner video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  -webkit-playsinline: true;
}

.mainbanner span,
h1 {
  color: #db93a5 !important;
}

.mainbanner span,
h3 {
  color: #202020 !important;
  margin-bottom: 10px !important;
}

.mainBannerCols {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 500px;
}

.container {
  max-width: 3000px !important;
}

/**************************** MAIN BANNER ****************************/

/**************************** ABOUT ****************************/

.categoryHeroImageWrapper {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.projectHeroImageWrapper {
  width: 100%;
  height: 500px;
  background-size: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  filter: grayscale(100%);
  transition: all 2s;
  cursor: pointer;
}

.projectHero-tile-overlay {
  position: absolute; /* position the overlay absolutely */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* black background with 50% opacity */
  opacity: 0.3;
  transition: opacity 0.5s;
}

.projectHero-text {
  font-family: Helvetica Neue Black;
  text-transform: uppercase;
  font-size: 3.5rem;
  color: #fff;
  text-align: center;
  opacity: 0;
  z-index: 5;
  padding: 5px;
  transition: all 0.3s;
  letter-spacing: 0.3em;
}

.projectHero-tile-overlay:hover {
  opacity: 0.1;
}

.projectHeroImageWrapper:hover {
  background-size: 110%;
  filter: grayscale(0%);
}

.projectHeroImageWrapper:hover .projectHero-text {
  opacity: 1; /* enlarge the text on hover */
}

#category-container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 3000px !important;
}

#work-page {
  height: 100%;
}

#contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 3000px !important;
}

.contacts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 650px;
  padding-left: 200px;
  padding-right: 200px;
}

#project-category-page h1 {
  font-size: 5rem;
  color: #2e2e2e !important;
  text-transform: uppercase;
  font-family: Helvetica Neue Black;
  letter-spacing: 8px;
  margin-top: 15rem !important;
  margin-bottom: 8rem !important;
}

#project-category-page p {
  text-align: left;
  color: #202020;
  font-size: 1.6rem;
  text-align: justify;
  font-family: Helvetica Neue Light;
  letter-spacing: 0.05em;
  line-height: 1.5em;
}

#project-category-page {
  justify-content: center;
  padding-left: 400px;
  padding-right: 400px;
  margin-top: 50px !important;
  margin-bottom: 100px !important;
}

#about-special {
  font-family: Helvetica Neue Black;
}

#about-logo {
  object-position: top left;
  width: 550px;
  padding: 8px;
  object-fit: cover;
}

#about-page {
  display: flex;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px !important;
  margin-bottom: 100px !important;
}

.about-logo-wrapper {
  margin-top: 10rem !important;
  display: flex;
  justify-content: center;
}

#about-page h1 {
  font-size: 5rem;
  color: #2e2e2e !important;
  text-transform: uppercase;
  font-family: Helvetica Neue Black;
  letter-spacing: 8px;
  margin-bottom: 8rem !important;
}

#about-page p {
  text-align: left;
  color: #000;
  font-size: 1.6rem;
  text-align: justify;
  font-family: Helvetica Neue Light;
  letter-spacing: 0.075em;
  line-height: 1.5em;
}

#about-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 400px;
  padding-right: 400px;
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

#about-section h1 {
  text-transform: lowercase !important;
  font-size: 5rem;
  text-shadow: -6px 6px 2px #ff3366;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

#about-section p {
  text-align: left;
  color: #08415c;
  font-size: 2.5rem;
  text-align: center;
  margin-top: 20px !important;
}

/**************************** ABOUT ****************************/

/**************************** CAROUSEL ****************************/

/**************************** CAROUSEL ****************************/

/**************************** CREATE MEMES ****************************/

/**************************** CREATE MEMES ****************************/

/**************************** DIVIDERS ****************************/

/**************************** DIVIDERS ****************************/
/*



Remember the goal, which is for job eployment. Focus on the samples, cluster and not overwhelm the employers. Feels like professional

HOME PAGE

HERO SECTION CONTENT
Hence, the big letters should be NAME (NADINE BERNARDINO), followed by the subheader, which are the professionS.

HERO SECTION DESIGN
Hence, it should also be straight to the point-- flat. Use Sans Serif font (Helvetica family), and font formats Bold, Light, and Regular only.









*/
/**************************** HOW TO BUY SECTION ****************************/

/**************************** HOW TO BUY SECTION ****************************/

/**************************** FOOTER ****************************/

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #011627;
  font-size: 1.4rem;
  font-weight: 300;
  padding-bottom: 20px;
}

.footer h1 {
  text-align: center;
  font-family: Helvetica Neue;
  font-style: normal !important;
  font-size: 2rem;
  color: #2e2e2e !important;
  letter-spacing: 0px;
}

/**************************** FOOTER ****************************/

/*************** CONTACT ****************/

#contact-page-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

#contact-page-container h1 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  color: #353535 !important;
}

#contact-page-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#contact-page-container label {
  width: 90%;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  display: block;
  font-size: 1.2rem;
  font-family: Helvetica Neue Light;
}

#contact-page-container button[type="submit"] {
  width: 90%;
  background-color: #444444;
  font-family: Helvetica Neue Light;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #fff;
  padding: 1.5em 2em;
  letter-spacing: 0.2em;
  border: none;
  cursor: pointer;
}

#contact-page-container input,
#contact-page-container textarea {
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #cccccc;
  font-size: 1.5rem;
}

#contact-page-container input[type="email"] {
  width: 100%;
}

#contact-page-container textarea {
  height: 100px;
  resize: vertical;
}

#contact-page-container button[type="submit"]:hover {
  background-color: #3e8e41;
}

#contact-page-container .success-message {
  color: #4caf50;
  font-size: 18px;
  margin-top: 20px;
}

#contact-page-container .error-message {
  color: rgb(255, 138, 138);
  font-size: 18px;
  margin-top: 20px;
}

/*************** CONTACT ****************/

/* Add this to your CSS file */
.masonry-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.masonry-grid_column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 300px; /* adjust this value to fit your image sizes */
  margin: 10px;
}

.masonry-grid_item {
  width: 100%;
  margin-bottom: 10px;
}

.masonry-grid_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/**************************** MODAL *******************************/

.imgmodal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  width: 100% !important;
  height: 100%;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.8);
}

.imgmodal-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  background-color: #272727 !important;
}

.modal {
  display: block !important; /* Hide the modal by default */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px;
}

.modal img {
  width: 500px !important;
  margin: 0 auto;
}

.modal-content {
  background-color: #f0f0f0;
  height: 80%;
  margin: 0 auto;
  margin-top: 5%;
  padding: 20px;
  border: 1px solid #ccc;
  width: 80%;
}

.modal-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.modalCloseBtn {
  width: 100%;
  font-family: Helvetica Neue Light;
  font-size: 1.8rem;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #2b2b2b;
  margin-top: 20px;
  border-radius: 4px;
  color: #000;
  letter-spacing: 0.05em;
}

.imgmodalCloseBtn {
  width: 100%;
  font-family: Helvetica Neue Light;
  font-size: 1.8rem;
  border-radius: 4px;
  text-transform: lowercase;
  background-color: transparent;
  margin-top: 20px;
  border: 1px solid #848484;
  color: #fff;
  letter-spacing: 0.05em;
}

/**************************** MODAL *******************************/

/**************************** RESPONSIVENESS ****************************/
@media screen and (max-width: 1150px) {
  #about-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  #base-display {
    width: 300px;
  }

  #meme-showcase {
    margin: 3vh auto !important;
    display: flex;
    width: 320px;
    height: 360px;
    padding: 1vmin;
  }

  #video-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }

  #buy-list {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 950px) {
  .base-image {
    width: 70%;
  }

  .head-overlay,
  .hand-overlay {
    left: 15%;
    width: 70%;
  }

  #logo {
    width: 300px;
  }
}

@media screen and (max-width: 500px) {
  .subPageHeader h1 {
    font-size: 3rem;
  }

  .dropdown-menu.show {
    transform: translate3d(60px, 32px, 0px) !important;
  }

  .contacts-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .categories-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .modal img {
    width: 325px !important;
    margin: 0 auto;
  }

  #contact-page-container form {
    padding: 20px 10px;
  }

  #contact-page-container label {
    width: 100%;
  }

  #contact-page-container button[type="submit"] {
    width: 100%;
  }

  .subPageHeader {
    margin-top: 5rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #project-category-page h1 {
    font-size: 3rem;
    margin-top: 5rem !important;
  }

  .projectHeroImageWrapper {
    height: 20rem;
  }

  #project-category-page {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px !important;
    margin-bottom: 100px !important;
  }

  .projectHero-text {
    font-size: 1.5rem;
    padding: 5px;
    font-family: Helvetica Neue Medium;
    letter-spacing: 0.1em;
    opacity: 100;
  }

  #about-logo {
    width: 200px;
    height: 200px;
    border-radius: 500px;
    padding: 4px;
  }

  #about-page {
    display: flex;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px !important;
    margin-bottom: 100px !important;
  }

  .about-logo-wrapper {
    margin-top: 3rem !important;
  }

  #about-page h1 {
    font-size: 3.5rem;
    color: #2e2e2e !important;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 4rem !important;
  }

  #about-page p {
    text-align: left;
    color: #202020;
    font-size: 1.7rem;
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.6em;
  }

  #menu-bar .row {
    margin: 0px !important;
  }

  #menu-bar {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #menu-bar h1 {
    font-size: 1.2rem;
    line-height: 2.3rem;
  }

  #meme-note {
    font-size: 1.5rem;
  }

  .overlay-parts select {
    font-size: 2rem;
    height: 3rem;
    width: 15rem;
    margin: 10px !important;
  }

  .base-image {
    width: 80%;
  }

  .head-overlay,
  .eyes-overlay,
  .hand-overlay,
  .background-overlay {
    left: 10%;
    width: 80%;
  }

  #video-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  #pop-video {
    border: double 0.5em transparent;
  }

  .mainbanner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    padding-bottom: 0px !important;
  }

  #logo-col {
    margin-bottom: 20px !important;
    padding-bottom: 10px !important;
  }

  #about-display {
    width: 300px;
  }

  #about-section {
    margin-top: 70px !important;
  }

  #about-section h1 {
    margin-top: 50px !important;
    text-shadow: -3px 3px 1px #ff3366;
    font-size: 3.5rem;
  }

  #about-section p {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  #create-section p {
    font-size: 1.5rem;
  }

  .carousel-container h1 {
    font-size: 3rem;
  }

  #carousel-section {
    padding-left: 5px;
    padding-right: 5px;
  }

  #create-section {
    padding-left: 10px;
    padding-right: 10px;
  }

  #create-section h1 {
    font-size: 3.5rem;
    text-shadow: -3px 3px 1px #ff3366;
  }

  #dressup-section h1 {
    font-size: 3.5rem;
    text-shadow: -3px 3px 1px #ff3366;
  }

  #base-display {
    width: 300px;
  }

  #meme-showcase {
    margin: 3vh auto !important;
    display: flex;
    width: 300px;
    height: 45vh;
    padding: 5vmin;
  }

  #about-section {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  #about-section p {
    font-size: 2rem;
  }

  .buy-guide {
    margin-top: 100px !important;
  }

  .buy-guide h1 {
    font-size: 3.5rem;
  }

  .buy-guide h3 {
    font-size: 2.5rem;
  }

  #buy-list {
    padding: 10px 20px !important;
    margin-top: 20px;
  }

  #buy-list li p {
    font-size: 1.5rem;
  }

  #logo {
    padding-top: 20px;
    width: 300px;
  }

  .header h1 {
    font-size: 3.5rem;
    margin-bottom: 50px !important;
  }

  .header h1::before,
  .header h1::after {
    width: 2rem;
  }

  .social-icon a {
    width: 50px;
    height: 50px;
  }

  .contractClip {
    box-shadow: -3px 4px 0 0 #bf4976;
  }

  .Toastify__toast-body {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center !important;
  }
}

/**************************** RESPONSIVENESS ****************************/

/**************************** TICKER ****************************/

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  border-top: 2px solid #ff3366;
  border-bottom: 2px solid #ff3366;
  background-color: rgba(219, 147, 165, 0.9);
  padding-left: 100%;
  box-sizing: content-box;
  z-index: 100;

  .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;

    .ticker__item {
      display: inline-block;
      font-weight: 900;
      font-family: Loved By The King, Haettenschweiler, "Arial Narrow Bold",
        sans-serif;
      padding: 0 2rem;
      font-size: 2.5rem;
      color: white;
    }
  }
}

/**************************** TICKER ****************************/
